html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

@font-face {
  font-family: silkscreen;
  src: url("slkscr-webfont.65e77080.ttf") format("truetype");
  font-display: block;
}

nav {
  word-spacing: 10px;
  border-bottom: 2px solid #bbb;
  margin-bottom: 40px;
  padding-top: 30px;
}

nav a {
  word-spacing: normal;
  border: 0 solid #ddd;
  border-color: #ddd #eee #555 #777;
  margin-bottom: 7px;
  margin-left: 0;
  padding: 10px;
  font-size: 1.4em;
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
}

nav a:before, nav a:after {
  content: "";
  border: 0 solid #0000;
  display: inline-block;
  position: absolute;
}

nav a:before {
  border-left-color: #0000;
  top: 0;
  left: 0;
}

nav a:after {
  bottom: 0;
  right: 0;
}

nav a:first-child {
  text-shadow: -3px 3px 1px #000;
  font-size: 5em;
  font-weight: 700;
  box-shadow: none !important;
  background: none !important;
  border: 0 !important;
}

nav a:first-child:before, nav a:first-child:after {
  content: none;
}

nav a:first-child:hover, nav a:first-child:focus {
  box-shadow: none;
  border: 0;
  margin: 0;
  padding: 10px;
  top: 0;
  left: 0;
}

nav a:hover, nav a:focus {
  border-bottom-width: 7px;
  border-left-width: 7px;
  margin-bottom: 0;
  margin-left: -7px;
  top: -7px;
  left: 7px;
  box-shadow: inset -1px 1px #777;
}

nav a:hover:after, nav a:focus:after {
  border-bottom: 7px solid #fff;
  border-left: 7px solid #0000;
  border-right: 7px solid #fff;
  bottom: -7px;
  right: -7px;
}

nav a, nav a:before, nav:after {
  transition: all 80ms linear;
}

nav a:active, #home:target [href="#home"] {
  background: #eee;
  border-width: 4px 4px 0 0;
  border-bottom-style: solid;
  border-bottom-color: #777;
  border-left-style: solid;
  border-left-color: #777;
  margin-left: 3.5px;
  margin-right: -3.5px;
  padding-bottom: 6px;
  padding-left: 6px;
  top: 4px;
  left: -4px;
  box-shadow: inset -1px 1px 11px -4px #000, inset 1px -1px #fff;
}

nav a:hover:before, nav a:focus:before, #home:target [href="#home"]:before {
  border-top: 7px solid #fff;
  border-bottom: 7px solid #0000;
  border-left: 7px solid #fff;
  top: -7px;
  left: -7px;
}

nav a:active, #resume:target [href="#resume"] {
  background: #eee;
  border-width: 4px 4px 0 0;
  border-bottom-style: solid;
  border-bottom-color: #777;
  border-left-style: solid;
  border-left-color: #777;
  margin-left: 3.5px;
  margin-right: -3.5px;
  padding-bottom: 6px;
  padding-left: 6px;
  top: 4px;
  left: -4px;
  box-shadow: inset -1px 1px 11px -4px #000, inset 1px -1px #fff;
}

nav a:hover:before, nav a:focus:before, #resume:target [href="#resume"]:before {
  border-top: 7px solid #fff;
  border-bottom: 7px solid #0000;
  border-left: 7px solid #fff;
  top: -7px;
  left: -7px;
}

nav a:active, #projects:target [href="#projects"] {
  background: #eee;
  border-width: 4px 4px 0 0;
  border-bottom-style: solid;
  border-bottom-color: #777;
  border-left-style: solid;
  border-left-color: #777;
  margin-left: 3.5px;
  margin-right: -3.5px;
  padding-bottom: 6px;
  padding-left: 6px;
  top: 4px;
  left: -4px;
  box-shadow: inset -1px 1px 11px -4px #000, inset 1px -1px #fff;
}

nav a:hover:before, nav a:focus:before, #projects:target [href="#projects"]:before {
  border-top: 7px solid #fff;
  border-bottom: 7px solid #0000;
  border-left: 7px solid #fff;
  top: -7px;
  left: -7px;
}

nav a:active, #links:target [href="#links"] {
  background: #eee;
  border-width: 4px 4px 0 0;
  border-bottom-style: solid;
  border-bottom-color: #777;
  border-left-style: solid;
  border-left-color: #777;
  margin-left: 3.5px;
  margin-right: -3.5px;
  padding-bottom: 6px;
  padding-left: 6px;
  top: 4px;
  left: -4px;
  box-shadow: inset -1px 1px 11px -4px #000, inset 1px -1px #fff;
}

nav a:hover:before, nav a:focus:before, #links:target [href="#links"]:before {
  border-top: 7px solid #fff;
  border-bottom: 7px solid #0000;
  border-left: 7px solid #fff;
  top: -7px;
  left: -7px;
}

nav a:active, #contact:target [href="#contact"] {
  background: #eee;
  border-width: 4px 4px 0 0;
  border-bottom-style: solid;
  border-bottom-color: #777;
  border-left-style: solid;
  border-left-color: #777;
  margin-left: 3.5px;
  margin-right: -3.5px;
  padding-bottom: 6px;
  padding-left: 6px;
  top: 4px;
  left: -4px;
  box-shadow: inset -1px 1px 11px -4px #000, inset 1px -1px #fff;
}

nav a:hover:before, nav a:focus:before, #contact:target [href="#contact"]:before {
  border-top: 7px solid #fff;
  border-bottom: 7px solid #0000;
  border-left: 7px solid #fff;
  top: -7px;
  left: -7px;
}

html {
  overflow-x: hidden;
  overflow-x: -moz-hidden-unscrollable;
  text-align: center;
  display: block !important;
}

body {
  text-shadow: -1px 1px 1px #000;
  width: 800px;
  margin: 0 auto;
  font-family: silkscreen, monospace, sans-serif;
  line-height: 20px;
  display: inline-block;
}

a {
  color: #bbb;
  text-decoration: none;
}

a:focus, a:hover {
  color: #777;
}

a:focus {
  outline: 0;
}

main {
  text-align: left;
  width: 4000px;
  transition: margin-left .8s linear .4s;
  display: table;
  position: relative;
}

main:before, main:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 100%;
  transition: left .8s linear .4s, right .8s linear .4s;
  position: absolute;
}

main:before {
  left: 0;
}

main:after {
  right: 0;
}

h3 {
  border-bottom: .1em solid #000;
  border-image: linear-gradient(to right, #000, #0000, #0000) 1;
  margin-bottom: 1em;
  padding-bottom: .3em;
  transform: translateX(-1em);
}

#container:before, #container:after {
  content: "";
  z-index: 101;
  border: 10px solid #0000;
  border-top: 0 solid #ddd;
  border-bottom: 0;
  width: 0;
  height: 0;
  transition: left .8s linear .4s;
  display: inline-block;
  position: absolute;
  top: 0;
}

#continer:before, #home:target #container:before {
  left: 0;
}

#continer:after, #home:target #container:after {
  left: 780px;
}

#resume:target #container:before {
  left: 800px;
}

#resume:target #container:after {
  left: 1580px;
}

#projects:target #container:before {
  left: 1600px;
}

#projects:target #container:after {
  left: 2380px;
}

#links:target #container:before {
  left: 2400px;
}

#links:target #container:after {
  left: 3180px;
}

#contact:target #container:before {
  left: 3200px;
}

#contact:target #container:after {
  left: 3980px;
}

section {
  float: left;
  box-sizing: border-box;
  border-top: 0 solid #ddd;
  width: 800px;
  padding: 10px 20px;
  display: table-cell;
  position: relative;
}

section:before, section:after {
  z-index: 100;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -10px;
}

section:before {
  left: -10px;
}

section:after {
  right: -10px;
}

main:before, main:after {
  z-index: 99;
  border: 0 solid #eee;
}

main, #home:target main {
  margin-left: 0;
}

main:before, #home:target main:before {
  left: -4000px;
}

main:after, #home:target main:after {
  right: -800px;
}

#resume:target main {
  margin-left: -800px;
}

#resume:target main:before {
  left: -3200px;
}

#resume:target main:after {
  right: -1600px;
}

#projects:target main {
  margin-left: -1600px;
}

#projects:target main:before {
  left: -2400px;
}

#projects:target main:after {
  right: -2400px;
}

#links:target main {
  margin-left: -2400px;
}

#links:target main:before {
  left: -1600px;
}

#links:target main:after {
  right: -3200px;
}

#contact:target main {
  margin-left: -3200px;
}

#contact:target main:before {
  left: -800px;
}

#contact:target main:after {
  right: -4000px;
}

@keyframes sections-fall-main-before-home {
  0%, 100% {
    border-right-width: 0;
  }

  25%, 75% {
    border-right-width: 10px;
  }
}

#home:target main:before {
  -khtml-animation: sections-fall-main-before-home 1.6s steps(30);
  -ms-animation: sections-fall-main-before-home 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-before-home;
}

@keyframes sections-fall-main-after-home {
  0%, 100% {
    border-left-width: 0;
  }

  25%, 75% {
    border-left-width: 10px;
  }
}

#home:target main:after {
  -khtml-animation: sections-fall-main-after-home 1.6s steps(30);
  -ms-animation: sections-fall-main-after-home 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-after-home;
}

@keyframes sections-fall-section-home {
  0%, 100% {
    border-top-width: 0;
  }

  25%, 75% {
    border-top-width: 8px;
  }
}

#home:target section {
  -khtml-animation: sections-fall-section-home 1.6s steps(30);
  -ms-animation: sections-fall-section-home 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-section-home;
}

@keyframes sections-fall-container-home {
  0%, 100% {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }

  25%, 75% {
    border-top-width: 8px;
    border-left-width: 10px;
    border-right-width: 10px;
  }
}

#home:target #container:before, #home:target #container:after {
  -khtml-animation: sections-fall-container-home 1.6s steps(30);
  -ms-animation: sections-fall-container-home 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-container-home;
}

@keyframes sections-fall-main-home {
  0%, 100% {
    box-shadow: 0;
  }

  25%, 75% {
    box-shadow: inset 0 -1px #eee;
  }
}

#home:target main {
  -khtml-animation: sections-fall-main-home 1.6s steps(30);
  -ms-animation: sections-fall-main-home 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-home;
}

@keyframes sections-fall-main-before-resume {
  0%, 100% {
    border-right-width: 0;
  }

  25%, 75% {
    border-right-width: 10px;
  }
}

#resume:target main:before {
  -khtml-animation: sections-fall-main-before-resume 1.6s steps(30);
  -ms-animation: sections-fall-main-before-resume 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-before-resume;
}

@keyframes sections-fall-main-after-resume {
  0%, 100% {
    border-left-width: 0;
  }

  25%, 75% {
    border-left-width: 10px;
  }
}

#resume:target main:after {
  -khtml-animation: sections-fall-main-after-resume 1.6s steps(30);
  -ms-animation: sections-fall-main-after-resume 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-after-resume;
}

@keyframes sections-fall-section-resume {
  0%, 100% {
    border-top-width: 0;
  }

  25%, 75% {
    border-top-width: 8px;
  }
}

#resume:target section {
  -khtml-animation: sections-fall-section-resume 1.6s steps(30);
  -ms-animation: sections-fall-section-resume 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-section-resume;
}

@keyframes sections-fall-container-resume {
  0%, 100% {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }

  25%, 75% {
    border-top-width: 8px;
    border-left-width: 10px;
    border-right-width: 10px;
  }
}

#resume:target #container:before, #resume:target #container:after {
  -khtml-animation: sections-fall-container-resume 1.6s steps(30);
  -ms-animation: sections-fall-container-resume 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-container-resume;
}

@keyframes sections-fall-main-resume {
  0%, 100% {
    box-shadow: 0;
  }

  25%, 75% {
    box-shadow: inset 0 -1px #eee;
  }
}

#resume:target main {
  -khtml-animation: sections-fall-main-resume 1.6s steps(30);
  -ms-animation: sections-fall-main-resume 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-resume;
}

@keyframes sections-fall-main-before-projects {
  0%, 100% {
    border-right-width: 0;
  }

  25%, 75% {
    border-right-width: 10px;
  }
}

#projects:target main:before {
  -khtml-animation: sections-fall-main-before-projects 1.6s steps(30);
  -ms-animation: sections-fall-main-before-projects 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-before-projects;
}

@keyframes sections-fall-main-after-projects {
  0%, 100% {
    border-left-width: 0;
  }

  25%, 75% {
    border-left-width: 10px;
  }
}

#projects:target main:after {
  -khtml-animation: sections-fall-main-after-projects 1.6s steps(30);
  -ms-animation: sections-fall-main-after-projects 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-after-projects;
}

@keyframes sections-fall-section-projects {
  0%, 100% {
    border-top-width: 0;
  }

  25%, 75% {
    border-top-width: 8px;
  }
}

#projects:target section {
  -khtml-animation: sections-fall-section-projects 1.6s steps(30);
  -ms-animation: sections-fall-section-projects 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-section-projects;
}

@keyframes sections-fall-container-projects {
  0%, 100% {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }

  25%, 75% {
    border-top-width: 8px;
    border-left-width: 10px;
    border-right-width: 10px;
  }
}

#projects:target #container:before, #projects:target #container:after {
  -khtml-animation: sections-fall-container-projects 1.6s steps(30);
  -ms-animation: sections-fall-container-projects 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-container-projects;
}

@keyframes sections-fall-main-projects {
  0%, 100% {
    box-shadow: 0;
  }

  25%, 75% {
    box-shadow: inset 0 -1px #eee;
  }
}

#projects:target main {
  -khtml-animation: sections-fall-main-projects 1.6s steps(30);
  -ms-animation: sections-fall-main-projects 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-projects;
}

@keyframes sections-fall-main-before-links {
  0%, 100% {
    border-right-width: 0;
  }

  25%, 75% {
    border-right-width: 10px;
  }
}

#links:target main:before {
  -khtml-animation: sections-fall-main-before-links 1.6s steps(30);
  -ms-animation: sections-fall-main-before-links 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-before-links;
}

@keyframes sections-fall-main-after-links {
  0%, 100% {
    border-left-width: 0;
  }

  25%, 75% {
    border-left-width: 10px;
  }
}

#links:target main:after {
  -khtml-animation: sections-fall-main-after-links 1.6s steps(30);
  -ms-animation: sections-fall-main-after-links 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-after-links;
}

@keyframes sections-fall-section-links {
  0%, 100% {
    border-top-width: 0;
  }

  25%, 75% {
    border-top-width: 8px;
  }
}

#links:target section {
  -khtml-animation: sections-fall-section-links 1.6s steps(30);
  -ms-animation: sections-fall-section-links 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-section-links;
}

@keyframes sections-fall-container-links {
  0%, 100% {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }

  25%, 75% {
    border-top-width: 8px;
    border-left-width: 10px;
    border-right-width: 10px;
  }
}

#links:target #container:before, #links:target #container:after {
  -khtml-animation: sections-fall-container-links 1.6s steps(30);
  -ms-animation: sections-fall-container-links 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-container-links;
}

@keyframes sections-fall-main-links {
  0%, 100% {
    box-shadow: 0;
  }

  25%, 75% {
    box-shadow: inset 0 -1px #eee;
  }
}

#links:target main {
  -khtml-animation: sections-fall-main-links 1.6s steps(30);
  -ms-animation: sections-fall-main-links 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-links;
}

@keyframes sections-fall-main-before-contact {
  0%, 100% {
    border-right-width: 0;
  }

  25%, 75% {
    border-right-width: 10px;
  }
}

#contact:target main:before {
  -khtml-animation: sections-fall-main-before-contact 1.6s steps(30);
  -ms-animation: sections-fall-main-before-contact 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-before-contact;
}

@keyframes sections-fall-main-after-contact {
  0%, 100% {
    border-left-width: 0;
  }

  25%, 75% {
    border-left-width: 10px;
  }
}

#contact:target main:after {
  -khtml-animation: sections-fall-main-after-contact 1.6s steps(30);
  -ms-animation: sections-fall-main-after-contact 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-after-contact;
}

@keyframes sections-fall-section-contact {
  0%, 100% {
    border-top-width: 0;
  }

  25%, 75% {
    border-top-width: 8px;
  }
}

#contact:target section {
  -khtml-animation: sections-fall-section-contact 1.6s steps(30);
  -ms-animation: sections-fall-section-contact 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-section-contact;
}

@keyframes sections-fall-container-contact {
  0%, 100% {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }

  25%, 75% {
    border-top-width: 8px;
    border-left-width: 10px;
    border-right-width: 10px;
  }
}

#contact:target #container:before, #contact:target #container:after {
  -khtml-animation: sections-fall-container-contact 1.6s steps(30);
  -ms-animation: sections-fall-container-contact 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-container-contact;
}

@keyframes sections-fall-main-contact {
  0%, 100% {
    box-shadow: 0;
  }

  25%, 75% {
    box-shadow: inset 0 -1px #eee;
  }
}

#contact:target main {
  -khtml-animation: sections-fall-main-contact 1.6s steps(30);
  -ms-animation: sections-fall-main-contact 1.6s steps(30);
  animation: 1.6s steps(30, end) sections-fall-main-contact;
}

ul {
  margin-bottom: 3em;
}

li {
  margin-top: 1em;
  margin-left: 3em;
  list-style: none;
}

p {
  margin: 1em 0;
}

.big {
  font-size: 2em;
}

.meter {
  width: 11em;
  display: inline-block;
}

.meter:before {
  content: "";
  letter-spacing: -.06em;
  white-space: pre-line;
  text-shadow: none;
}

.meter[value="0"]:before {
  content: "☆☆☆☆☆" "\a " "No Experience";
}

.meter[value="1"]:before {
  content: "★☆☆☆☆" "\a " "Little Experience";
}

.meter[value="2"]:before {
  content: "★★☆☆☆" "\a " "Some Experience";
}

.meter[value="3"]:before {
  content: "★★★☆☆" "\a " "Comfortable";
}

.meter[value="4"]:before {
  content: "★★★★☆" "\a " "Very Comfortable";
}

.meter[value="5"]:before {
  content: "★★★★★" "\a " "Proficient";
}

#resume_ .position {
  text-shadow: 0 0 1px #000;
  font-weight: 700;
}

#resume_ #languages ul {
  -khtml-columns: 2;
  -moz-columns: 2;
  -ms-columns: 2;
  -o-columns: 2;
  -webkit-columns: 2;
  columns: 2;
}

#resume_ #languages li {
  border-left: 2px solid #000;
  padding-left: 1em;
  display: inline-block;
}

#links_ img {
  vertical-align: middle;
  width: 1em;
  height: 1em;
}

@media (width <= 799px) {
  body {
    width: 100%;
  }

  nav {
    margin-bottom: initial;
    z-index: 99;
    background: #fff;
    width: 100%;
  }

  nav.mobile-scroll {
    position: fixed;
  }

  nav a {
    display: block;
  }

  nav a:first-child {
    margin-bottom: .5em;
  }

  main {
    width: 100%;
    margin-left: initial !important;
  }

  main:before, main:after {
    content: none;
  }

  section {
    width: 100%;
  }

  #home:target [href="#home"], #home:target [href="#home"]:hover, #home:target [href="#home"]:active, #resume:target [href="#resume"], #resume:target [href="#resume"]:hover, #resume:target [href="#resume"]:active, #projects:target [href="#projects"], #projects:target [href="#projects"]:hover, #projects:target [href="#projects"]:active, #links:target [href="#links"], #links:target [href="#links"]:hover, #links:target [href="#links"]:active, #contact:target [href="#contact"], #contact:target [href="#contact"]:hover, #contact:target [href="#contact"]:active {
    box-shadow: none;
    color: #bbb;
    background: none;
    border: 0;
    margin-bottom: 7px;
    margin-left: 0;
    margin-right: 0;
    padding: 10px;
    top: 0;
    left: 0;
  }

  #home_:before {
    content: "D";
  }

  #resume_:before {
    content: "Resume";
  }

  #projects_:before {
    content: "Projects";
  }

  #links_:before {
    content: "Links";
  }

  #contact_:before {
    content: "Contact Me";
  }

  section:before {
    left: initial;
    position: initial;
    border-bottom: 2px solid #000;
    width: 100%;
    height: 1em;
    margin-bottom: 1em;
    font-size: 2em;
    display: inline-block;
  }

  section:first-child:before {
    content: none !important;
  }

  #resume_ #languages ul {
    -khtml-columns: 1;
    -moz-columns: 1;
    -ms-columns: 1;
    -o-columns: 1;
    -webkit-columns: 1;
    columns: 1;
    display: block;
  }

  #resume_ #languages li {
    padding-left: 1em;
    display: block;
  }
}
/*# sourceMappingURL=index.10c9832c.css.map */
